import React, { useEffect, useState } from "react";
import phaseimg1 from "./Assets/phase111.png";
import phaseimg2 from "./Assets/phase222.png";
import phaseimg3 from "./Assets/phase333.png";
import phaseimg4 from "./Assets/phase444.png";
import phaseimg5 from "./Assets/phase555.png";
import liimg from "./Assets/li_img.png";

function Roadmap() {

  return (

    <div className="pb-3">

      <h1 className="nebula text-white text-center text-uppercase pt-5 pb-4 big_font">
        Roadmap and <br></br> development timeline
      </h1>

      <div className="row">
        <div className="col-xl-11 col-xxl-9 mx-auto">
          <div className="d-flex pos_list gap-3">
            <img className="img-fluid phaseimg" src={phaseimg1} />
            <div>
              <h2 class="nebula text-white">Phase 1:</h2>
              <h3 className="text-white nebula overflow_wraps mb-0 ">Landfall (Q4 2024)</h3>
            </div>
          </div>
          <ul className="roadmap_section mt-4">
            <li><img src={liimg} /> <span>15 tokens representing Galfi resources are minted</span></li>
            <li><img src={liimg} /> <span>Soft Staking of 17 NFT collections for daily GALFI, GFMNR,
              GFORE, HYPER, TETRA, and AMRIT rewards</span></li>
            <li><img src={liimg} /> <span>Galfi Nexus NFT Marketplace created</span></li>
            <li><img src={liimg} /> <span>Micro Shuttle Starter Ships NFTS created</span></li>
            <li><img src={liimg} /> <span>Mining, Exploration, Social, and Combat missions created</span></li>
            <li><img src={liimg} /> <span>Buildings created</span></li>
            <li><img src={liimg} /> <span>Remaining 21 Ships and Mechs NFTS created</span></li>
            <li><img src={liimg} /> <span>10K Crew NFTS created with XP points system</span></li>
          </ul>


          <div className="d-flex pos_list gap-3 mt-5">
            <img className="img-fluid phaseimg" src={phaseimg2} />
            <div>
            <h2 class="nebula text-white">Phase 2:</h2>
              <h3 className="text-white nebula overflow_wraps mb-0">Reinforcements (Q4 2024 / Q1 2025)
              </h3>
            </div>
          </div>
          <ul className="roadmap_section mt-4">
            <li><img src={liimg} /> <span>10K Specialist NFTS created with XP points system</span></li>
          </ul>



          <div className="d-flex pos_list gap-3 mt-5">
            <img className="img-fluid phaseimg" src={phaseimg3} />
            <div>
            <h2 class="nebula text-white">Phase 3:</h2>
              <h3 className="text-white nebula overflow_wraps mb-0">Colonisation (Q1 2025 / Q2 2025)
              </h3>
            </div>
          </div>
          <ul className="roadmap_section mt-4">
            <li><img src={liimg} /> <span>Colony Ships created</span></li>
            <li><img src={liimg} /> <span>1024 system star map created so people can start
              colonising Planets and Asteroids in Contested Space</span></li>
            <li><img src={liimg} /> <span>Random events for exploration in Contested Space created
            </span></li>
            <li><img src={liimg} /> <span>PVP rules introduced for ship battles and for invading other people's
              colonies (in Contested Space only, NFT Planets and Asteroids in the
              Safe Zone will always be safe.</span></li>
          </ul>


          <div className="d-flex pos_list gap-3 mt-5">
            <img className="img-fluid phaseimg" src={phaseimg4} />
            <div>
            <h2 class="nebula text-white">Phase 4:</h2>
              <h3 className="text-white nebula overflow_wraps mb-0">Mission Control (Q1 2025 / Q2 2025)
              </h3>
            </div>
          </div>
          <ul className="roadmap_section mt-4">
            <li><img src={liimg} /> <span>Random events on missions
            </span></li>
            <li><img src={liimg} /> <span>More complicated mini games on missions
            </span></li>
          </ul>


          <div className="d-flex pos_list gap-3 mt-5">
            <img className="img-fluid phaseimg" src={phaseimg5} />
            <div>
            <h2 class="nebula text-white">Phase 5:</h2>
              <h3 className="text-white nebula overflow_wraps mb-0">Deep Future (Q1 2025 / Q2 2025 Onwards)
              </h3>
            </div>
          </div>
          <ul className="roadmap_section mt-4">
            <li><img src={liimg} /> <span>New uses for all 15 GalFi tokens in-game, new buildings,
            and new ships may be released in coming updates
            </span></li>
            <li><img src={liimg} /> <span>More existing NFT collections added through partnerships with
            these NFT projects, facilitating further growth and awareness of GalFi</span></li>
            <li><img src={liimg} /> <span>Bounties will be offered to those who help build out the Galfi Metaverse,
            and provide further utility for GalFi tokenised resources and NFTs</span></li>
            <li><img src={liimg} /> <span>Galfi Metaverse may continue to be built out in new and surprising ways
            </span></li>
          </ul>


        </div>

      </div>

    </div>


  )
}

export default Roadmap